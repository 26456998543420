import { ArgJSONMap } from "@multimediallc/web-utils"
import { addColorClass, colorClass } from "../../../cb/colorClasses"
import { getCb, normalizeResource } from "../../api"
import { i18n } from "../../translation"
import { naturalTime } from "../timeUtils"
import { BaseSettingsModal } from "./baseSettingsModal"

interface IFan {
    username: string,
    created_at: string,
    total: number,
}
interface IMember {
    username: string,
    months: number,
}

export interface IFanClubData {
    fans: IFan[]
    old_members: IMember[],
    all_members: IMember[],
}

function parseFanClubModal(rawData: string): IFanClubData {
    const dataMap = new ArgJSONMap(rawData)
    return {
        fans: parseFans(dataMap.getList("fans")),
        old_members: parseMembers(dataMap.getList("old_members")),
        all_members: parseMembers(dataMap.getList("all_members")),
    }
}

function parseFans(list: ArgJSONMap[] = []): IFan[] {
    const data = []
    for (const fan of list) {
        data.push({
            username: fan.getString("username"),
            created_at: fan.getString("created_at"),
            total: fan.getNumber("total"),
        })
    }
    return data
}

function parseMembers(list: ArgJSONMap[] = []): IMember[] {
    const data = []
    for (const fan of list) {
        data.push({
            username: fan.getString("username"),
            months: fan.getNumber("months"),
        })
    }
    return data
}

export class FanClubModal extends BaseSettingsModal<IFanClubData> {
    private contentContainer = document.createElement("div")
    private emptyMessageContainer: HTMLDivElement
    private fanClubTable = this.createTable()
    private fanClubTitle = this.createStyledTitle()
    private fanClubTableBody = document.createElement("tbody")
    private membersTable = this.createTable()
    private membersTitle = this.createStyledTitle()
    private membersTableBody = document.createElement("tbody")

    constructor() {
        super()

        this.contents.style.width = "550px"
        this.contents.style.padding = "10px"
        this.contents.appendChild(this.contentContainer)

        const fanClubContainer = this.createTableContainer()
        const membersContainer = this.createTableContainer()

        fanClubContainer.appendChild(this.fanClubTable)
        membersContainer.appendChild(this.membersTable)

        this.setupTitles()

        const fanClubInfo = document.createElement("p")
        addColorClass(fanClubInfo, colorClass.textColor)
        fanClubInfo.innerText = i18n.currentFanClubMembersInfo
        fanClubInfo.style.textAlign = "center"
        const membersInfo = document.createElement("p")
        addColorClass(membersInfo, colorClass.textColor)
        membersInfo.style.textAlign = "center"
        // eslint-disable-next-line @multimediallc/no-inner-html
        membersInfo.innerHTML = i18n.allFanClubMembersInfo

        this.contentContainer.appendChild(this.fanClubTitle)
        this.contentContainer.appendChild(fanClubInfo)
        this.contentContainer.appendChild(fanClubContainer)
        this.fanClubTable.appendChild(this.createFanClubTableHeader())
        this.fanClubTable.appendChild(this.fanClubTableBody)

        this.contentContainer.appendChild(document.createElement("br"))
        this.contentContainer.appendChild(document.createElement("hr"))

        this.contentContainer.appendChild(this.membersTitle)
        this.contentContainer.appendChild(membersInfo)
        this.contentContainer.appendChild(membersContainer)
        this.membersTable.appendChild(this.createMembersTableHeader())
        this.membersTable.appendChild(this.membersTableBody)

        this.emptyMessageContainer = document.createElement("div")
        this.hideEmptyMessage()
        this.emptyMessageContainer.appendChild(this.createEmptyMessage())
        this.contents.appendChild(this.emptyMessageContainer)
    }

    loadData(): Promise<IFanClubData | void> {
        return getCb("api/ts/chat/fan-list/").then((xhr) => {
            return parseFanClubModal(xhr.responseText)
        })
    }

    protected onDataLoaded(): void {
        if (this.data.all_members.length === 0) {
            this.displayEmptyMessage()
        } else {
            this.generateTables()
            this.updateTitles()
        }
    }

    private createTable(): HTMLTableElement {
        const table = document.createElement("table")
        table.style.width = "100%"
        table.style.borderCollapse = "collapse"

        return table
    }

    private createStyledTitle(): HTMLElement {
        const title = document.createElement("div")
        addColorClass(title, "bluetxt")
        title.style.fontSize = "1.4em"
        title.style.fontFamily = "'UbuntuMedium', Arial, Helvetica, sans-serif"
        title.style.fontWeight = "normal"
        title.style.textAlign = "center"
        title.style.marginTop = "20px"

        return title
    }

    private createTableContainer(): HTMLDivElement {
        const container = document.createElement("div")
        addColorClass(container, "select")
        container.style.width = "100%"
        container.style.height = `${document.documentElement.clientHeight / 3.5}px`
        container.style.borderWidth = "1px"
        container.style.borderStyle = "solid"
        container.style.borderRadius = "4px"
        container.style.overflowY = "scroll"

        return container
    }

    private setupTitles(): void {
        this.fanClubTitle.innerText = i18n.currentFanClubMembersTitle(this.getNumberOfMembers())
        this.membersTitle.innerText = "All Members"
    }

    private updateTitles(): void {
        this.fanClubTitle.innerText = i18n.currentFanClubMembersTitle(this.getNumberOfMembers())
    }

    private createEmptyMessage(): HTMLElement {
        const message = document.createElement("span")
        addColorClass(message, "bluetxt")
        message.innerText = "No Current or Previous Fan Club Members"
        message.innerText = "No Current or Previous Fan Club Members"
        message.style.fontSize = "1.4em"
        message.style.fontFamily = "'UbuntuMedium', Arial, Helvetica, sans-serif"
        message.style.fontWeight = "normal"
        message.style.textAlign = "center"
        message.style.marginTop = "20px"

        return message
    }

    private displayEmptyMessage(): void {
        this.contentContainer.style.display = "none"
        this.emptyMessageContainer.style.display = ""
    }

    private hideEmptyMessage(): void {
        this.emptyMessageContainer.style.display = "none"
        this.contentContainer.style.display = ""
    }

    private getNumberOfMembers(): number {
        if (this.data === undefined) {
            return 0
        }
        return this.data.fans.length
    }

    private generateTables(): void {
        this.clearTables()
        for (const row of this.data.fans) {
            this.fanClubTableBody.appendChild(this.createFanClubRow(row))
        }
        for (const row of this.data.all_members) {
            this.membersTableBody.appendChild(this.createMembersRow(row, this.isCurrentMember(row.username)))
        }
    }

    private clearTables(): void {
        while (this.fanClubTableBody.firstChild !== null) {
            this.fanClubTableBody.removeChild(this.fanClubTableBody.firstChild)
        }
        while (this.membersTableBody.firstChild !== null) {
            this.membersTableBody.removeChild(this.membersTableBody.firstChild)
        }
    }

    private createFanClubRow(data: IFan): HTMLElement {
        const row = document.createElement("tr")
        row.style.height = "22px"
        row.style.verticalAlign = "middle"
        row.style.borderBottom = "1px solid darkgray"
        row.appendChild(this.createRowName(data.username))
        row.appendChild(this.createRowSince(data.created_at))
        row.appendChild(this.createRowMonths(data.total))

        return row
    }

    private createMembersRow(data: IMember, isCurrent: boolean): HTMLElement {
        const row = document.createElement("tr")
        row.style.height = "22px"
        row.style.verticalAlign = "middle"
        row.style.borderBottom = "1px solid darkgray"
        if (isCurrent) {
            row.style.fontWeight = "bold"
        }
        row.appendChild(this.createRowName(data.username))
        row.appendChild(this.createRowMonths(data.months))

        return row
    }

    private createRowName(username: string): HTMLElement {
        const td = document.createElement("td")
        const name = document.createElement("a")
        addColorClass(name, "bluetxt")
        name.href = normalizeResource(`/${username}`)
        name.target = "_blank"
        name.innerText = username
        name.style.fontSize = "15px"
        name.style.textAlign = "left"
        name.style.textOverflow = "ellipsis"
        name.style.overflow = "hidden"
        name.style.paddingLeft = "12px"
        name.style.marginTop = "4px"

        td.appendChild(name)
        return td
    }

    private createRowSince(time: string): HTMLElement {
        const td = document.createElement("td")
        td.title = `Since ${new Date(time).toString()}`
        td.style.fontSize = "12px"
        td.style.textAlign = "center"
        td.style.textOverflow = "ellipsis"
        td.style.overflow = "hidden"
        td.style.paddingLeft = "6px"
        td.style.marginTop = "4px"
        td.innerText = naturalTime(new Date(), new Date(time))

        return td
    }

    private createRowMonths(months: number): HTMLElement {
        const td = document.createElement("td")
        td.style.fontSize = "12px"
        td.style.textAlign = "center"
        td.style.textOverflow = "ellipsis"
        td.style.overflow = "hidden"
        td.style.paddingLeft = "6px"
        td.style.marginTop = "4px"
        td.innerText = `${months} month${months === 1 ? "" : "s"}`

        return td
    }

    private createFanClubTableHeader(): HTMLDivElement {
        const tableHead = document.createElement("thead")
        tableHead.style.borderBottom = "2px solid darkgray"

        const row = document.createElement("tr")
        row.appendChild(this.createTableHeaderCell(i18n.usernameText))
        row.appendChild(this.createTableHeaderCell(i18n.lastJoined))
        row.appendChild(this.createTableHeaderCell(i18n.totalMonthsAMember))

        tableHead.appendChild(row)
        return tableHead
    }

    private createMembersTableHeader(): HTMLDivElement {
        const tableHead = document.createElement("thead")
        tableHead.style.borderBottom = "2px solid darkgray"

        const row = document.createElement("tr")
        row.appendChild(this.createTableHeaderCell(i18n.usernameText))
        row.appendChild(this.createTableHeaderCell(i18n.totalMonthsAMember))

        tableHead.appendChild(row)
        return tableHead
    }

    private createTableHeaderCell(text: string): HTMLDivElement {
        const th = document.createElement("th")
        th.innerText = text
        th.style.textAlign = "center"
        th.style.fontSize = "1.2em"
        th.style.padding = "3px"

        return th
    }

    private isCurrentMember(username: string): boolean {
        for (const member of this.data.old_members) {
            if (member.username === username) {
                return false
            }
        }
        return true
    }
}
