import twemoji from "@twemoji/api"
import { BannedTermTypes, parseBannedTerms } from "../../../cb/api/chatBannedTerms"
import { addColorClass, colorClass } from "../../../cb/colorClasses"
import { Toggle } from "../../../cb/components/toggle"
import { buildTooltip } from "../../../cb/ui/tooltip"
import { addEventListenerPoly } from "../../addEventListenerPolyfill"
import { modalAlert } from "../../alerts"
import { deleteCb, getCb, postCb, putCb } from "../../api"
import { DivotPosition } from "../../divot"
import { applyStyles, getTextWidthFont } from "../../DOMutils"
import { isCharacterOrBackspaceKey } from "../../eventsUtil"
import { i18n } from "../../translation"
import { dom } from "../../tsxrender/dom"
import { BaseSettingsModal } from "./baseSettingsModal"
import { SettingsTable } from "./settingsTable"
import type { IBannedTerm, IBannedTermsData } from "../../../cb/api/chatBannedTerms"
import type { XhrError } from "../../api"

/**
 * @styles: scss/theme/shared/baseSettingsModal.scss
 */
export class BannedTermsModal extends BaseSettingsModal<IBannedTermsData> {
    private table: SettingsTable<IBannedTerm>
    private page: number | undefined
    private paginationLimit = 12
    private searchTerm = ""
    private apiUrl = "api/ts/chat/banned-terms-list/"
    private termInput: HTMLInputElement

    constructor() {
        super()

        addColorClass(this.element, colorClass.textColor)
        this.element.style.overflow = "visible"
        this.contents.style.width = "720px"
        this.contents.style.padding = "10px"

        this.table = new SettingsTable({
            title: i18n.bannedTerms,
            info: i18n.bannedTermsInfo,
            onPageRequest: (page) => {
                this.page = page
                this.reload()
            },
            rowHeaders: [this.createHeaderTerm(), this.createHeaderSearchSubstrings(), i18n.remove],
            rowGenerators: [
                (props) => this.createRowTerm(props),
                (props) => this.createRowSearchSubstrings(props),
                (props) => this.createRowRemove(props),
            ],
            minHeight: "360px",
        })

        this.contents.appendChild(this.table.element)
        this.contents.appendChild(this.createAddTermForm())
    }

    protected loadData(): Promise<void | IBannedTermsData> {
        let url = this.apiUrl
        if (this.searchTerm !== "") {
            url = `${this.apiUrl}?limit=${this.paginationLimit}&search_term=${this.searchTerm}`
            this.searchTerm = ""
        } else if (this.page !== undefined) {
            url = `${this.apiUrl}?page=${this.page}&limit=${this.paginationLimit}`
        }
        return getCb(url).then((xhr) => {
            return parseBannedTerms(xhr.responseText)
        })
    }

    protected onDataLoaded(): void {
        this.page = this.data.currentPage
        this.table.updatePagination(this.data.pageCount, this.page)
        this.generateTable()
        if (this.termInput.value !== "") {
            this.termInput.focus()
        }
        this.termInput.value = ""
    }

    private generateTable(): void {
        this.table.reset()
        for (const term of this.data.bannedTerms) {
            this.table.addRow(term)
        }
    }

    private createHeaderTerm(): HTMLElement {
        return <div style={{ width: "308px" }}>
            {i18n.term}
        </div>
    }

    private createHeaderSearchSubstrings(): HTMLElement {
        const helpIconStyle: CSSX.Properties = {
            width: "14px",
            height: "14px",
            background: `transparent url(${STATIC_URL}broadcastnotification/infoicon.svg) no-repeat right 50%`,
            backgroundSize: "contain",
            display: "inline-block",
            marginLeft: "4px",
            position: "relative",
            verticalAlign: "top",
        }

        const tooltip = buildTooltip({
            content: i18n.banAsSubstringExplanation,
            hasHTML: false,
            width: getTextWidthFont(i18n.banAsSubstringExplanation, "12px UbuntuLight") + 8,
            divotPosition: DivotPosition.Bottom,
            divotLeftOrTop: "28px",
        })
        applyStyles(tooltip, {
            bottom: "20px",
            left: "-32px",
            textAlign: "left",
            maxWidth: "280px",
            fontFamily: "UbuntuLight, Helvetica, Arial, sans-serif",
        })

        return <div>
            { i18n.banAsSubstring }
            <span style={helpIconStyle}
                onMouseEnter={() => tooltip.style.display = ""}
                onMouseLeave={() => tooltip.style.display = "none"}>
                { tooltip }
            </span>
        </div>
    }

    private createRowTerm(props: IBannedTerm): HTMLTableCellElement {
        const styles: CSSX.Properties = {
            textAlign: "center",
            fontSize: "14px",
            overflowWrap: "break-word",
            hyphens: "auto",
            width: "300px",
            paddingLeft: "8px",
            display: "inline-block",
            lineHeight: "25px",
        }
        const termEl = <td style={styles}>
            { props.term }
        </td>
        twemoji.parse(termEl)
        return termEl
    }

    private createRowSearchSubstrings(props: IBannedTerm): HTMLTableCellElement {
        const styles: CSSX.Properties = {
            textAlign: "center",
            padding: "4px 0",
        }
        const toggle = new Toggle(props.banType === BannedTermTypes.Substring, () => {}, { width: 28, height: 16 })
        toggle.setOnChange(() => {
            const ban_type = toggle.isChecked() ? BannedTermTypes.Substring : BannedTermTypes.Exact
            putCb(this.apiUrl, { "term": props.term, "ban_type": ban_type }).catch(() => {
                toggle.setCheckedDirectly(!toggle.isChecked())
                modalAlert(i18n.errorUpdatingTerm)
            })
        })
        return <td style={styles}>
            { toggle.element }
        </td>
    }

    private createRowRemove(term: IBannedTerm): HTMLTableCellElement {
        const styles: CSSX.Properties = {
            margin: "auto",
            width: "14px",
            height: "14px",
            cursor: "pointer",
            opacity: ".5",
        }
        const closeButton = <div colorClass="removeButton"
            style={styles}
            onClick={() => this.removeTerm(term)}
            onMouseEnter={() => {closeButton.style.opacity = "1"}}
            onMouseLeave={() => {closeButton.style.opacity = ".5"}}/>

        return <td>{ closeButton }</td>
    }

    private createAddTermForm(): HTMLElement {
        const formStyle: CSSX.Properties = {
            fontSize: "1.12em",
            fontFamily: "'UbuntuMedium', Arial, Helvetica, sans-serif",
            fontWeight: "normal",
            margin: "20px 0 8px",
            textAlign: "center",
        }
        const inputStyle: CSSX.Properties = {
            width: "160px",
            height: "16px",
            margin: "0 8px",
        }
        const submitStyle: CSSX.Properties = {
            cursor: "pointer",
            fontFamily: "'UbuntuMedium', Arial, Helvetica, sans-serif",
        }

        this.termInput = <input style={inputStyle}
            type="text"
            name="term"
            maxLength={30}
            placeholder={i18n.enterTermToBan}/>

        addEventListenerPoly("keydown", document, (e: KeyboardEvent) => {
            if (document.body.contains(this.element) && document.activeElement !== this.termInput && isCharacterOrBackspaceKey(e)) {
                this.termInput.focus()
            }
        })

        const onSubmit = (event: Event) => {
            event.preventDefault()
            const term = this.termInput.value.trim()
            if (term === "") {
                modalAlert(i18n.pleaseEnterTermToBan, () => this.termInput.focus())
                return
            }
            postCb(this.apiUrl, { "term": term }).then(() => {
                this.searchTerm = term
                this.reload()
            }).catch((err: XhrError): void => {
                const response = JSON.parse(err.xhr.responseText)
                if (typeof response["error"] === "string") {
                    const error = JSON.parse(response["error"])
                    modalAlert(error["term"][0]["message"], () => this.termInput.focus())
                } else {
                    modalAlert(i18n.errorBanningTerm, () => this.termInput.focus())
                }
            })
        }

        return <form colorClass="bluetxt" style={formStyle} onSubmit={onSubmit}>
            <label>{i18n.addTerm}:</label>
            { this.termInput }
            <button style={submitStyle} type="submit" colorClass="submitButton">{i18n.inlineBanText}</button>
        </form>
    }

    private removeTerm(term: IBannedTerm): void {
        deleteCb(this.apiUrl, { "term": term.term }).then(() => {
            this.reload()
        }).catch(() => {
            modalAlert(i18n.errorRemovingTerm)
        })
    }
}
