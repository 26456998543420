import { ArgJSONMap } from "@multimediallc/web-utils"
import { addColorClass, colorClass } from "../../../cb/colorClasses"
import { modalAlert } from "../../alerts"
import { getCb, postCb } from "../../api"
import { roomLoaded } from "../../context"
import { i18n } from "../../translation"
import { BaseSettingsModal } from "./baseSettingsModal"

export interface IModeratorsData {mods: string[]}

function parseMods(rawData: string): IModeratorsData {
    const dataMap = new ArgJSONMap(rawData)
    return { mods: dataMap.getStringList("mods") }
}

let roomName: string

// eslint-disable-next-line @multimediallc/no-global-listener
roomLoaded.listen((context) => {
    roomName = context.chatConnection.room()
})

export class ModeratorsModal extends BaseSettingsModal<IModeratorsData> {
    private modSelect: HTMLSelectElement
    private unmodSelect: HTMLSelectElement

    constructor() {
        super()

        const header = document.createElement("div")
        addColorClass(header, "bluetxt")
        header.innerText = i18n.removeModerators
        header.style.fontSize = "15px"
        header.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        header.style.textAlign = "center"
        this.contents.appendChild(header)

        const helpText = document.createElement("div")
        addColorClass(helpText, colorClass.textColor)
        helpText.innerText = i18n.clickToUnmodText
        helpText.style.margin = "10px 0"
        helpText.style.textAlign = "center"
        this.contents.appendChild(helpText)

        const moderatorsWrapper = document.createElement("div")
        moderatorsWrapper.style.display = "inline-block"
        moderatorsWrapper.style.marginRight = "10px"

        const moderatorsLabel = document.createElement("div")
        addColorClass(moderatorsLabel, "bluetxt")
        moderatorsLabel.innerText = i18n.moderators
        moderatorsLabel.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"
        moderatorsLabel.style.fontWeight = "bold"
        moderatorsWrapper.appendChild(moderatorsLabel)

        this.modSelect = document.createElement("select")
        addColorClass(this.modSelect, "select")
        this.modSelect.multiple = true
        this.modSelect.style.borderWidth = "1px"
        this.modSelect.style.borderStyle = "solid"
        this.modSelect.style.borderRadius = "4px"
        this.modSelect.style.marginTop = "4px"
        this.modSelect.style.width = "200px"
        this.modSelect.style.height = "200px"
        this.modSelect.style.padding = "2px"
        this.modSelect.onchange = () => {
            const selectedCount = this.modSelect.selectedOptions.length
            const selectedList = [...this.modSelect.selectedOptions]
            for (let i = 0; i < selectedCount; i += 1) {
                const selectedOption = selectedList[i]
                selectedOption.selected = false
                this.unmodSelect.appendChild(selectedOption)
            }
        }
        moderatorsWrapper.appendChild(this.modSelect)

        this.contents.appendChild(moderatorsWrapper)

        const nonModeratorsWrapper = document.createElement("div")
        nonModeratorsWrapper.style.display = "inline-block"

        const nonModeratorsLabel = document.createElement("div")
        addColorClass(nonModeratorsLabel, "bluetxt")
        nonModeratorsLabel.innerText = i18n.nonModerators
        nonModeratorsLabel.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"
        nonModeratorsLabel.style.fontWeight = "bold"
        nonModeratorsWrapper.appendChild(nonModeratorsLabel)

        this.unmodSelect = document.createElement("select")
        addColorClass(this.unmodSelect, "select")
        this.unmodSelect.multiple = true
        this.unmodSelect.style.borderWidth = "1px"
        this.unmodSelect.style.borderStyle = "solid"
        this.unmodSelect.style.borderRadius = "4px"
        this.unmodSelect.style.marginTop = "4px"
        this.unmodSelect.style.width = "200px"
        this.unmodSelect.style.height = "200px"
        this.unmodSelect.style.padding = "2px"
        this.unmodSelect.onchange = () => {
            const selectedCount = this.unmodSelect.selectedOptions.length
            const selectedList = [...this.unmodSelect.selectedOptions]
            for (let i = 0; i < selectedCount; i += 1) {
                const selectedOption = selectedList[i]
                selectedOption.selected = false
                this.modSelect.appendChild(selectedOption)
            }
        }
        nonModeratorsWrapper.appendChild(this.unmodSelect)

        this.contents.appendChild(nonModeratorsWrapper)

        const saveButtonWrapper = document.createElement("div")
        saveButtonWrapper.style.textAlign = "right"

        const saveButton = document.createElement("span")
        addColorClass(saveButton, "saveButton")
        addColorClass(saveButton, "gradient")
        saveButton.innerText = i18n.saveCAPS
        saveButton.style.display = "inline-block"
        saveButton.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        saveButton.style.fontSize = "14px"
        saveButton.style.padding = "5px 15px 5px 15px"
        saveButton.style.marginTop = "10px"
        saveButton.style.borderRadius = "4px"
        saveButton.style.cursor = "pointer"
        saveButtonWrapper.appendChild(saveButton)
        saveButton.onclick = () => {
            for (const option of this.unmodSelect.options) {
                postCb(`removemoderator/${option.value}/${roomName}/`, {}).then().catch(() => {
                    modalAlert(`An error occurred removing moderator status from ${option.value}`)
                })
            }
            this.hide()
        }

        this.contents.appendChild(saveButtonWrapper)
    }

    loadData(): Promise<IModeratorsData | void> {
        return getCb("api/ts/chat/mod-list/").then((xhr) => {
            return parseMods(xhr.responseText)
        })
    }

    protected onDataLoaded(): void {
        while (this.modSelect.firstChild !== null) {
            this.modSelect.removeChild(this.modSelect.firstChild)
        }
        while (this.unmodSelect.firstChild !== null) {
            this.unmodSelect.removeChild(this.unmodSelect.firstChild)
        }
        for (const user of this.data.mods) {
            const option = document.createElement("option")
            option.innerText = user
            option.value = user
            this.modSelect.appendChild(option)
        }
    }
}
