import { isRoomRoomlistSpaActive } from "@multimediallc/cb-roomlist-prefetch"
import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { addColorClass } from "../../cb/colorClasses"
import { PageLocation, spaPageContext } from "../../cb/interfaces/context"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { multiAppPanel } from "../appPanel"
import { Component } from "../defui/component"
import { EventRouter } from "../events"
import { getViewportHeight, getViewportWidth } from "../mobilelib/viewportDimension"
import { addPageAction } from "../newrelic"
import { sdRatio, wsRatio } from "../player/playerSettings"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import type { BaseRoomContents } from "../baseRoomContents"

export const resizeHandleWidth = 8

const storageKey = "defaultVideoWidth"
export const preferredMinVideoWidth = multiAppPanel ? 548 : 502 // min video width when chat is above min chat width
export const minVideoHeight = 300
const defaultMinChatWidth = 155
const defaultChatWidth = 332
const verticalBuffer = 166

type ResizeHistoryItem = {
    videoMode: VideoMode,
    videoWidth: number,
    videoHeight: number,
    chatWidth: number,
    chatHeight: number,
}

export class ResizeHandle extends Component {
    protected state = {
        videoWidth: preferredMinVideoWidth,
        videoHeight: minVideoHeight,
    }
    private isDragging = false
    private resizeHistory: ResizeHistoryItem[] = []
    private lastChosenVideoWidth = preferredMinVideoWidth
    private lastChosenChatWidth = defaultChatWidth
    static splitModeDragResize = new EventRouter<void>("splitModeDragResize")

    constructor(protected roomContents: BaseRoomContents) {
        super()

        this.element.style.position = ""
        this.element.style.display = "inline-block"
        this.element.style.width = `${resizeHandleWidth}px`
        this.element.style.boxSizing = "border-box"

        addEventListenerPoly("mousedown", this.element, (event: MouseEvent) => {
            event.preventDefault()
            this.roomContents.element.onmousemove = (event: MouseEvent) => {
                const desiredVideoWidth = event.pageX - roomContents.videoPanel.element.getBoundingClientRect().left - this.element.offsetWidth / 2
                this.handleResize(desiredVideoWidth)
                this.lastChosenVideoWidth = desiredVideoWidth
                this.lastChosenChatWidth = this.getChatWidthFromVideoWidth(this.lastChosenVideoWidth)
                this.roomContents.repositionChildrenRecursive()
            }

            this.roomContents.element.onmouseup = () => {
                this.handleResizeFinished()
            }

            this.roomContents.element.onmouseleave = () => {
                this.handleResizeFinished()
            }

            this.isDragging = true
        })

        addEventListenerPoly("touchmove", this.element, (event: TouchEvent) => {
            event.preventDefault()
            if (event.targetTouches.length !== 1) {
                return
            }
            this.roomContents.element.ontouchmove = () => {
                const touch = event.targetTouches.item(0)
                if (touch !== null) {
                    const desiredVideoWidth = touch.pageX - roomContents.videoPanel.element.getBoundingClientRect().left - this.element.offsetWidth / 2
                    this.handleResize(desiredVideoWidth)
                    this.lastChosenVideoWidth = desiredVideoWidth
                    this.lastChosenChatWidth = this.getChatWidthFromVideoWidth(this.lastChosenVideoWidth)
                    this.roomContents.repositionChildrenRecursive()
                }
            }

            this.roomContents.element.ontouchend = () => {
                this.handleResizeFinished()
            }

            this.isDragging = true
        })

        window.setTimeout(() => {
            this.element.style.cursor = this.getCursor()
            addColorClass(this.element, "resizeHandle")
            this.loadSettings()
            this.handleResize()
            this.handleResizeFinished()
        }, 0)

        videoModeHandler.changeVideoMode.listen(() => {
            if (videoModeHandler.getVideoMode() === VideoMode.Split) {
                this.loadSettings()
                this.handleResize()
            }
        })
    }

    private getChatWidthFromVideoWidth(videoWidth: number): number {
        return this.roomContents.getAvailableWidth() - videoWidth - resizeHandleWidth
    }

    private getVideoWidthFromChatWidth(chatWidth: number): number {
        return this.roomContents.getAvailableWidth() - chatWidth - resizeHandleWidth
    }

    private handleResizeSplitMode(targetChatWidth: number): void {
        const videoPanelWidth = this.calculateSplitModeVideoPanelWidth(targetChatWidth)
        this.roomContents.videoPanel.element.style.width = `${videoPanelWidth}px`
        const chatWidth = this.getChatWidthFromVideoWidth(videoPanelWidth)

        // Prevent video size jitter while shrinking/expanding the window
        if (chatWidth > this.minChatWidth()) {
            this.roomContents.videoPanel.element.style.minWidth = `${preferredMinVideoWidth}px`
        }
        if (chatWidth < targetChatWidth) {
            this.roomContents.videoPanel.element.style.maxWidth  = `${preferredMinVideoWidth}px`
        }

        if (!multiAppPanel) {
            this.roomContents.videoPanel.updateAppPanel()
        }
        ResizeHandle.splitModeDragResize.fire()
    }

    // eslint-disable-next-line complexity
    public handleResize(desiredVideoWidth?: number): void {
        // don't resize video when navigating away from room page
        if (isRoomRoomlistSpaActive() && spaPageContext.getState().pageLocation !== PageLocation.RoomPage) {
            return
        }
        let videoWidth = Math.max(desiredVideoWidth ?? this.lastChosenVideoWidth, preferredMinVideoWidth + 2)
        videoWidth = Math.min(this.getMaxVideoWidth(), videoWidth)
        const currentVideoMode = videoModeHandler.getVideoMode()

        this.roomContents.videoPanel.element.style.minWidth = ""
        this.roomContents.videoPanel.element.style.maxWidth = ""
        switch (currentVideoMode) {
            case VideoMode.Split:
                if (desiredVideoWidth === undefined) {
                    this.handleResizeSplitMode(this.lastChosenChatWidth)
                    videoWidth = this.roomContents.videoPanel.element.offsetWidth
                } else {
                    this.handleResizeSplitMode(this.getChatWidthFromVideoWidth(videoWidth))
                }
                break
            case VideoMode.Theater:
                this.roomContents.videoPanel.element.style.width = `${this.roomContents.getAvailableWidth()}px`
                break
            case VideoMode.IFS:
                this.roomContents.videoPanel.element.style.width = `${window.innerWidth}px`
                break
            case VideoMode.VideoOnly:
                break
            case VideoMode.Fullscreen:
                break
            default:
                error(`Unexpected VideoMode: ${currentVideoMode}`)
        }

        this.state.videoWidth = videoWidth
        this.state.videoHeight = this.getHeightFromWidth(videoWidth)
        if (currentVideoMode === VideoMode.Split) {
            const cursor = this.getCursor()
            this.element.style.cursor = cursor
            this.roomContents.element.style.cursor = cursor
        }
    }

    private recordResizeHistory(): void {
        const chatWidth = parseInt(this.roomContents.chatTabContainer.element.style.width)
        const chatHeight = parseInt(this.roomContents.chatTabContainer.element.style.height)
        const lastResize = this.resizeHistory[this.resizeHistory.length - 1]
        const currentResize =  {
            videoMode: videoModeHandler.getVideoMode(),
            videoWidth: this.state.videoWidth,
            videoHeight: this.state.videoHeight,
            chatWidth,
            chatHeight,
        }
        const sizeChanged = lastResize === undefined || lastResize.videoWidth !== currentResize.videoWidth || lastResize.videoHeight !== currentResize.videoHeight
        if (sizeChanged || lastResize.videoMode !== currentResize.videoMode) {
            this.resizeHistory.push(currentResize)
        }
    }

    private handleResizeFinished(): void {
        this.recordResizeHistory()
        if (this.resizeHistory.length === 2) {
            const [previousSize, currentSize] = this.resizeHistory
            const eventAttributes = {
                "video_width_old": previousSize.videoWidth,
                "video_height_old": previousSize.videoHeight,
                "video_width_new": currentSize.videoWidth,
                "video_height_new": currentSize.videoHeight,
                "chat_width_old": previousSize.chatWidth,
                "chat_height_old": previousSize.chatHeight,
                "chat_width_new": currentSize.chatWidth,
                "chat_height_new": currentSize.chatHeight,
            }
            addPageAction("VideoResized", eventAttributes)
            this.resizeHistory = this.resizeHistory.slice(1)
        }
        if (videoModeHandler.getVideoMode() === VideoMode.Split) {
            this.saveSettings()
            this.roomContents.element.onmousemove = () => {}
            this.roomContents.element.onmouseup = () => {}
            this.roomContents.element.onmouseleave = () => {}
            this.roomContents.element.ontouchend = () => {}
            this.roomContents.element.ontouchmove = () => {}
            this.roomContents.element.style.cursor = ""
            this.isDragging = false
        }
    }

    protected getMaxVideoWidth(): number {
        const roomContentsWidth = this.roomContents.getAvailableWidth()
        if (videoModeHandler.getVideoMode() === VideoMode.Split) {
            return roomContentsWidth - this.minChatWidth()
        }
        return roomContentsWidth
    }

    protected getMaxVideoHeight(): number {
        return window.innerHeight - verticalBuffer
    }

    private getCursor(): string {
        const videoWidth = this.roomContents.videoPanel.element.offsetWidth
        if (videoWidth <= preferredMinVideoWidth) {
            return "e-resize"
        } else if (videoWidth >= this.getMaxVideoWidth()) {
            return "w-resize"
        }
        return "ew-resize"
    }

    private loadSettings(): void {
        if (isLocalStorageSupported()) {
            const savedSettings = window.localStorage.getItem(storageKey)
            if (savedSettings !== null) {
                const importedState = JSON.parse(savedSettings)
                this.state = {
                    videoWidth: importedState["videoWidth"],
                    videoHeight: importedState["videoHeight"],
                }
                this.lastChosenVideoWidth = importedState.lastChosenVideoWidth ?? this.state.videoWidth
                this.lastChosenChatWidth = importedState.lastChosenChatWidth ?? defaultChatWidth
                return
            }
        }
        if (videoModeHandler.getVideoMode() === VideoMode.Split) {
            this.setResolutionToMax({ maximizeWidth: true })
            this.lastChosenVideoWidth = this.state.videoWidth
        }
    }

    private saveSettings(): void {
        if (isLocalStorageSupported()) {
            window.localStorage.setItem(storageKey, JSON.stringify({
                "videoWidth": this.state.videoWidth,
                "videoHeight": this.state.videoHeight,
                "lastChosenVideoWidth": this.lastChosenVideoWidth,
                "lastChosenChatWidth": this.lastChosenChatWidth,
            }))
        }
    }

    protected repositionChildren(): void {
        this.handleResize()
        if (!this.isDragging) {
            this.handleResizeFinished()
        }
    }

    public setResolutionToMax({ maximizeWidth } = { maximizeWidth: false }): void {
        const viewportWidth = getViewportWidth()
        let height, width
        if (this.getMaxVideoWidth() - 95 > viewportWidth / 2) {
            if (maximizeWidth) {
                const viewportHeight = getViewportHeight()
                const windowAspectRatio = viewportHeight / viewportWidth
                if (windowAspectRatio > this.getVideoAspectRatio()) {
                    width = this.getMaxVideoWidth() - 95
                } else {
                    const tipAreaOffset = 200
                    const sourceHeight = viewportHeight - tipAreaOffset
                    width = this.getWidthFromHeight(sourceHeight)
                }
            } else {
                width = viewportWidth / 2
            }
            height = this.getHeightFromWidth(width)
        } else {
            height = this.getMaxVideoHeight()
            width = this.getWidthFromHeight(height)
        }
        if (!maximizeWidth) {
            if (height > this.getMaxVideoHeight()) {
                height = this.getMaxVideoHeight()
                width = this.getWidthFromHeight(height)
            }
        }
        if (width < preferredMinVideoWidth) {
            width = preferredMinVideoWidth
        }
        this.state.videoWidth = width
        this.state.videoHeight = height
    }

    protected getVideoAspectRatio(): number {
        return this.roomContents.videoPanel.player.getIsWidescreen() ? wsRatio : sdRatio
    }

    protected getHeightFromWidth(videoWidth: number): number {
        return videoWidth * this.getVideoAspectRatio()
    }

    protected getWidthFromHeight(videoHeight: number): number {
        return videoHeight / this.getVideoAspectRatio()
    }

    protected minChatWidth(): number {
        return defaultMinChatWidth
    }

    public calculateSplitModeVideoPanelWidth(targetChatWidth?: number): number {
        let chatWidth = Math.min(targetChatWidth ?? this.lastChosenChatWidth, this.getChatWidthFromVideoWidth(preferredMinVideoWidth))
        chatWidth = Math.max(chatWidth, this.minChatWidth())
        return this.getVideoWidthFromChatWidth(chatWidth)
    }

    public calculateHeightFromWidth(videoWidth: number): number {
        return this.getHeightFromWidth(videoWidth)
    }
}
