import { type IPMError, type ISendPrivateMessage, PrivateMessageSource, sendPrivateMessage } from "../../cb/api/pm"
import { pageContext } from "../../cb/interfaces/context"
import { BaseTab, TabId } from "../../cb/ui/tabs"
import { roomCleanup, roomLoaded } from "../context"
import { ChatMode, createAppsRunningMessage } from "../messageToDOM"
import { addPageAction } from "../newrelic"
import { ignoreCatch } from "../promiseUtils"
import { i18n } from "../translation"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { ChatTabContents } from "./chatTabContents"
import { createLogMessage } from "./messageToDOM"
import { openDefaultTipCalloutRequest } from "./userActionEvents"
import type { IShortcodeForm } from "../../cb/interfaces/shortcode"
import type { IChatWindowTab } from "../../cb/ui/iChatTab"
import type { IChatConnection } from "../context"
import type { CustomInput } from "../customInput"
import type { IOutgoingMessageHandlers } from "../specialoutgoingmessages"

export class ChatTab extends BaseTab implements IChatWindowTab {
    private numUnread = 0
    public chatTabContents: ChatTabContents
    private chatConnection: IChatConnection

    constructor() {
        super()
        this.element.style.overflow = ""

        const onShortcode = (shortcode: IShortcodeForm) => {
            this.chatConnection.sendShortcode(shortcode)
        }
        const baseOutgoingHandlers: IOutgoingMessageHandlers = {
            onToggleDebugMode: () => {
                this.chatConnection.toggleAppDebugging()
            },
            onTipRequest: (tipRequest) => {
                openDefaultTipCalloutRequest.fire(tipRequest)
            },
            onChatMessage: (msg) => {
                // use PM API if in a private show
                if (this.chatConnection.inPrivateRoom()) {
                    const pm: ISendPrivateMessage = {
                        message: msg,
                        username: pageContext.current.isBroadcast || this.chatConnection.isBroadcasting
                            ? this.chatConnection.getPrivateShowUser()
                            : this.chatConnection.room(),
                        media: undefined,
                        source: PrivateMessageSource.RoomViewPM,
                        roomName: this.chatConnection.room(),
                    }

                    sendPrivateMessage(pm).catch((error: IPMError) => {
                        this.chatTabContents.appendMessageDiv(createLogMessage(error.errorMessage))
                    })
                } else {
                    this.chatConnection.sendRoomMessage(msg)
                    this.chatTabContents.blurCurrentChatInput()
                }
            },
        }
        this.chatTabContents = new ChatTabContents({
            onShortcode,
            ...baseOutgoingHandlers,
        }, () => this.chatConnection.inPrivateRoom(), () => this.isConversationShowing())
        this.addChild(this.chatTabContents)

        roomLoaded.listen((context) => {
            this.chatConnection = context.chatConnection

            this.chatTabContents.appendMessageDiv(createLogMessage(this.welcomeWarning()), false)
            this.chatTabContents.appendMessageDiv(createLogMessage(this.welcomeMessage()), false)

            createAppsRunningMessage(
                context.dossier.room, context.dossier.roomUid, context.dossier.appsRunning, ChatMode.Theater,
            ).then((appsMessage) => {
                this.chatTabContents.appendMessageDiv(appsMessage, false)
            }).catch(ignoreCatch)
        })

        roomCleanup.listen(() => {
            this.chatTabContents.clear()
        })
    }

    public scrollToBottom(): void {
        this.chatTabContents.scrollToBottom()
    }

    protected getTabHandleContent(): Node[] {
        const baseTabTitle = i18n.chatCAPS
        let message
        if (this.numUnread > 99) {
            message = `${baseTabTitle} (99+)`
        } else if (this.numUnread > 0) {
            message = `${baseTabTitle} (${this.numUnread})`
        } else {
            message = baseTabTitle
        }
        return [document.createTextNode(message)]
    }

    public getTabId(): TabId {
        return TabId.ChatDefault
    }

    public focusCurrentChatInput(): void {
        this.chatTabContents.focusCurrentChatInput()
    }

    public blurCurrentChatInput(): void {
        this.chatTabContents.blurCurrentChatInput()
    }

    public isInputFocused(): boolean {
        return this.chatTabContents.isInputFocused()
    }

    public possiblyIncrementUnread(): void {
        if (!this.isCurrentTab()) {
            this.numUnread += 1
        }
        this.refreshTabs()
    }

    public showElement(): void {
        super.showElement()
        this.resetNumUnread()
        this.chatTabContents.clearScrollButtonUnread()
        this.chatTabContents.scrollToBottom()
    }

    private isConversationShowing(): boolean {
        const isActiveVideoMode = videoModeHandler.getVideoMode() === VideoMode.Split
        const currTabId = this.parent?.getCurrentTab().getTabId()
        const isChatOrPMTabShowing = currTabId === TabId.ChatDefault || currTabId === TabId.PrivateDefault
        // In private shows, both PMTab and ChatTab are used for the private show chat
        const inPrivateAndAnyChatShowing = this.chatConnection && this.chatConnection.inPrivateRoom() && isChatOrPMTabShowing // eslint-disable-line @typescript-eslint/strict-boolean-expressions
        return (this.isCurrentTab() || inPrivateAndAnyChatShowing) && isActiveVideoMode
    }

    public resetNumUnread(): void {
        this.numUnread = 0
        this.refreshTabs()
    }

    public handleRemoveMessages(u: string): void {
        const removeList: HTMLElement[] = []
        for (const div of this.chatTabContents.messageList.childNodes) {
            const casted = div as HTMLElement
            if (casted.getAttribute("data-nick") === u) {
                removeList.push(casted)
            }
        }
        for (const msg of removeList) {
            this.chatTabContents.messageList.removeChild(msg)
        }
    }

    public appendInputText(text: string): void {
        this.chatTabContents.appendInputText(text)
    }

    public getChatInputField(): CustomInput {
        return this.chatTabContents.customInputField
    }

    protected welcomeWarning(): string {
        return i18n.viewerWelcomeWarning
    }
    protected welcomeMessage(): string {
        return i18n.welcomeMessage
    }

    protected tabHandleClicked(_: MouseEvent): void {
        super.tabHandleClicked(_)
        addPageAction("FocusTab", { "location": "chat" })
    }
}
