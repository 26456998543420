
import { Component } from "../../common/defui/component"
import { dom } from "../../common/tsxrender/dom"

type PaginationProps = {
    requestNewPage: (arg0: number) => void,
    overideStyle?: CSSX.Properties,
}

enum PageStatus {
    More = -100,
    Left = -200,
    Right = -300,
}

export class Pagination extends Component {
    private currentPage = 1
    private pageCount = 1
    private pagiList: HTMLUListElement

    constructor(private props: PaginationProps) {
        super()

        const pagiStyle: CSSX.Properties = {
            height: "25px",
            listStyle: "none",
            padding: 0,
            display: "inline-block",
            marginBottom: 0,
            font: "1.166em/1.577em 'UbuntuMedium',Arial,Helvetica,sans-serif",
            ...this.props.overideStyle,
        }
        this.element = <div style={{ textAlign: "center" }} colorClass="pagination">
            <ul style={pagiStyle} ref={(el: HTMLUListElement) => this.pagiList = el} />
        </div>

        this.updatePageDOM()
    }

    private gotoPage(page: number | PageStatus): void {
        if (page === PageStatus.Left) {
            if (this.currentPage === 1) {
                return
            }
            this.currentPage -= 1
        } else if (page === PageStatus.Right) {
            if (this.currentPage === this.pageCount) {
                return
            }
            this.currentPage += 1
        } else {
            this.currentPage = page
        }

        this.props.requestNewPage(this.currentPage)

        this.updatePageDOM()
    }

    private range(from: number, to: number, step = 1): number[] {
        let i = from
        const range = []

        while (i <= to) {
            range.push(i)
            i += step
        }
        return range
    }

    updatePageDOM(): void {
        const leftBound = this.currentPage - 3
        const rightBound = this.currentPage + 3
        const startPage = leftBound > 2 ? leftBound : 2
        const endPage = rightBound < this.pageCount ? rightBound : this.pageCount - 1
        const currentArea = this.range(startPage, endPage)
        if (startPage > 2) {
            currentArea.unshift(PageStatus.More)
        }
        if (endPage < this.pageCount - 1) {
            currentArea.push(PageStatus.More)
        }
        const pages = [PageStatus.Left, 1, ...currentArea, this.pageCount, PageStatus.Right]

        const pagiItemStyle: CSSX.Properties = {
            cssFloat: "left",
            minWidth: "21px",
            height: "21px",
            margin: "0 4px",
            textAlign: "center",
            lineHeight: "21px",
        }
        const pagiItemInnerStyle: CSSX.Properties = {
            display: "inline-block",
            minWidth: "21px",
            height: "21px",
            textDecoration: "none",
            cursor: "pointer",
            borderRadius: "5px",
            lineHeight: "20px",
        }

        while (this.pagiList.firstChild !== null) {
            this.pagiList.removeChild(this.pagiList.firstChild)
        }

        pages.map(page => {
            let pageItemDOM: HTMLLIElement
            if (page === PageStatus.More) {
                pageItemDOM = <li style={pagiItemStyle}>...</li>
            } else if (page === PageStatus.Left || page === PageStatus.Right) {
                const arrow = page === PageStatus.Left ? "◀" : "▶"
                pageItemDOM = <li style={pagiItemStyle} onClick={() => {
                    this.gotoPage(page)
                }}>
                    <a style={pagiItemInnerStyle} colorClass="sidePagiAnchorColor">{arrow}</a>
                </li>
            } else {
                if (page === this.currentPage) {
                    pageItemDOM = <li style={pagiItemStyle} colorClass="pagiActiveAnchorColor">{page}</li>
                } else {
                    pageItemDOM = <li style={pagiItemStyle} onClick={() => {
                        this.gotoPage(page)
                    }}>
                        <a style={pagiItemInnerStyle} colorClass="pagiAnchorColor" className="pagiAnchor">{page}</a>
                    </li>
                }
            }
            this.pagiList.appendChild(pageItemDOM)
        })
    }

    updatePageInfo(count: number, page: number): void {
        this.pageCount = count
        this.currentPage = page
        this.updatePageDOM()
    }
}
