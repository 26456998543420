import { getBanID } from "../../cb/api/chatBansAndSilences"
import { dmsEnabled, getUnreadPms } from "../../cb/api/pm"
import { addColorClass } from "../../cb/colorClasses"
import { DesktopDMChatLink } from "../../cb/components/pm/chatLinks"
import { createDmWindowRequest } from "../../cb/components/pm/dmWindowsManager"
import { allPmsRead, directMessage, privateMessage } from "../../cb/components/pm/userActionEvents"
import { MentionUserList } from "../../cb/components/userMentions/MentionUserList"
import { pageContext } from "../../cb/interfaces/context"
import { ExpandableDropDownMenu } from "../../cb/ui/expandableDropDownMenu"
import { BaseTabsContainer } from "../../cb/ui/tabs"
import { swapColors } from "../chatcolors/darkModeColors"
import { goToSettingsTab } from "../chatSettingsUtil"
import { createUndoOptions } from "../chatUndoOptions"
import { roomCleanup, roomLoaded } from "../context"
import { ConversationNotifier } from "../conversationNotifier"
import { DmNotifier } from "../dmNotifier"
import { applyStyles } from "../DOMutils"
import { ListenerGroup } from "../events"
import { isPSRequestPMTabRedirectActive } from "../featureFlagUtil"
import { ignoreCatch } from "../promiseUtils"
import { SplitRoomNotice } from "../roomNotice"
import { RoomNoticeDeclutterer } from "../roomNoticeDeclutterer"
import { RoomStatus } from "../roomStatus"
import { isCurrentUserRoomOwner } from "../roomUtil"
import { i18n } from "../translation"
import { appDebuggingToggled, mentionUser, repositionChatTabContent, userInitiatedPm } from "../userActionEvents"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { ChatSettingsTab } from "./chatSettingsTab"
import { ChatTab } from "./chatTab"
import { inputDivHeight } from "./chatTabContents"
import {
    createLogMessage,
    createPMChatLinkMessage,
    createRoomMessage,
    createRoomPhotoMessage,
    createShortcodeMessage,
} from "./messageToDOM"
import { PmTab } from "./pmTab"
import { ResizeHandle } from "./resizeHandle"
import { userCountUpdate, UserListTab } from "./userListTab"
import type { IPMAnnouncement } from "../../cb/components/pm/chatLinks"
import type { IChatTabContainer, IChatWindowTab } from "../../cb/ui/iChatTab"
import type { BaseTab } from "../../cb/ui/tabs"
import type { IRoomContext } from "../context"
import type { Component } from "../defui/component"
import type { IBanSilenceInfo, IPrivateMessage, IPushPrivateMessage, IRemoveMessagesNotification, IRoomMessage, IRoomNotice } from "../messageInterfaces"
import type { RoomNotice } from "../roomNotice"

export class ChatTabContainer extends BaseTabsContainer implements IChatTabContainer {
    public chatTab = this.createChatTab()
    private userListTab = new UserListTab()
    public pmTab: PmTab
    private settingsTab = new ChatSettingsTab()
    protected currentTab: IChatWindowTab
    private expandableDropDownMenu: ExpandableDropDownMenu
    private roomNoticeDeclutterer: RoomNoticeDeclutterer
    private readonly perRoomListeners: ListenerGroup
    private conversationNotifier?: ConversationNotifier
    private inPrivateRoom = false
    private privateShowUser = ""
    private room = ""
    readonly tabHeight = 22

    constructor(private inBroadcast = false) {
        super()
        this.perRoomListeners = new ListenerGroup()
        this.tabHandlesRow.element.style.boxSizing = "border-box"
        this.tabHandlesRow.element.style.borderBottom = ""
        this.tabHandlesRow.element.style.height = `${this.tabHeight}px`

        this.window.style.width = ""
        this.window.style.position = ""
        this.window.style.height = `calc(100% - ${this.tabHeight}px)`

        this.element.id = "ChatTabContainer"
        this.element.style.display = "inline-block"
        this.element.style.position = ""
        this.element.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"
        this.element.style.borderRadius = "4px 4px 2px 2px"
        this.element.style.margin = "0"
        this.element.style.boxSizing = "border-box"
        this.element.style.overflow = "hidden"
        this.element.style.height = "0"
        this.element.style.minHeight = "100%"

        this.tabHandleStyle = {
            ...this.tabHandleStyle,
            position: "static",
            fontSize: "12px",
            marginRight: "2px",
            padding: "3px 8px",
        }

        const addPmTab = () => {
            this.pmTab = this.addTab(new PmTab(() => {
                this.changeToTab(this.chatTab)
            }, this.inBroadcast))
            this.pmTab.showElement()
        }

        this.chatTab = this.addTab(this.chatTab)

        addPmTab()
        this.userListTab = this.addTab(this.userListTab)
        this.settingsTab = this.addTab(this.settingsTab)
        this.tabHandlesRow.element.style.padding = "1px 3px 0 3px"
        this.settingsTab.handle.element.dataset["pactionName"] = "Settings"
        this.userListTab.handle.element.dataset["pactionName"] = "USERS"

        this.expandableDropDownMenu = this.constructExpandableDropDownMenu()
        this.tabHandlesRow.addChild(this.expandableDropDownMenu)
        this.tabHandlesRow.element.dataset["paction"] = "Chat"
        this.changeToTab(this.chatTab)

        this.initNoticeDeclutterer()

        roomLoaded.listen((context) => {
            this.room = context.chatConnection.room()

            if (!this.inBroadcast) {
                this.changeToTab(this.chatTab)
            }
            if (!isCurrentUserRoomOwner()) {
                userInitiatedPm.fire({
                    username: context.dossier.room,
                    focus: false,
                    showSupporterAlert: context.dossier.needsSupporterToPm,
                })
            }

            context.chatConnection.event.roomShortcode.listen((m) => {
                const shortcodeMsgDiv = createShortcodeMessage(m)
                this.chatTab.chatTabContents.appendMessageDiv(shortcodeMsgDiv)
            })

            context.chatConnection.event.roomMessage.listen((m) => {
                this.newChatMessage(m)
            })
            context.chatConnection.event.roomNotice.listen((m) => {
                this.newRoomNotice(m)
            })
            context.chatConnection.event.removeMessages.listen((removeMessages: IRemoveMessagesNotification) => {
                this.chatTab.handleRemoveMessages(removeMessages.username)
                this.chatTab.chatTabContents.removeMessagesForUser(removeMessages.username)
                this.pmTab.closePMSession(removeMessages.username)
            })
            context.chatConnection.event.statusChange.listen((roomStatusChangeNotification) => {
                this.inPrivateRoom = context.chatConnection.inPrivateRoom()
                this.privateShowUser = context.chatConnection.getPrivateShowUser()
                this.loadUnreadPmsForPrivateShow()

                if (roomStatusChangeNotification.previousStatus === RoomStatus.NotConnected && this.currentTab === this.userListTab) {
                    this.userListTab.refresh()
                }
            })
            context.chatConnection.event.appDebugLog.listen((m) => {
                if (context.chatConnection.isAppDebuggingEnabled()) {
                    this.chatTab.chatTabContents.appendMessageDiv(createLogMessage(`DEBUG: ${m}`))
                }
                if (pageContext.current.isTestbed && window.console !== undefined) {
                    // Enables "cb.log" and "console.log" in apps and bots SDK. Intended for use in production.
                    info(m)
                }
            })

            context.chatConnection.event.onBanSilence.listen((evt) => {
                this.handleBanSilence(evt, context)  // eslint-disable-line @typescript-eslint/no-floating-promises
            })

            this.conversationNotifier = new ConversationNotifier(context.chatConnection)

            if (pageContext.current.mergePmDm) {
                directMessage.listen((m: IPushPrivateMessage) => {
                    if (this.conversationNotifier?.shouldNotify(m.fromUser.username) === true) {
                        const msgDiv = this.conversationNotifier.createChatLinkMessage(m.fromUser.username)
                        this.chatTab.chatTabContents.appendMessageDiv(msgDiv)
                        this.conversationNotifier.preventNotifications(m.otherUsername)
                    }
                }).addTo(this.perRoomListeners)
            }

            this.settingsTab.getChatSettings().hideModals()
        })

        if (!pageContext.current.mergePmDm) {
            const dmNotifier = new DmNotifier()
            if (dmsEnabled()) {
                directMessage.listen((m: IPushPrivateMessage) => {
                    if (dmNotifier.shouldNotify(m.fromUser.username)) {
                        const msg = i18n.newDirectMessageNotice(m.otherUsername)
                        const chatLink = new DesktopDMChatLink({
                            onClick: () => {
                                createDmWindowRequest.fire(m.otherUsername)
                            },
                        })
                        const msgDiv = createPMChatLinkMessage(msg, chatLink.openConversationElement)
                        this.chatTab.chatTabContents.appendMessageDiv(msgDiv)
                        dmNotifier.preventNotifications(m.otherUsername)
                    }
                })
            }
        }

        if (!pageContext.current.mergePmDm) {
            this.pmTab.otherUserInitiatedPm.listen((pmAnnouncement) => {
                if (this.conversationNotifier?.shouldNotify(pmAnnouncement.username) === true) {
                    this.newPmChatLink(pmAnnouncement)
                    this.conversationNotifier.preventNotifications(pmAnnouncement.username)
                }
            })
        }

        roomCleanup.listen(() => {
            this.perRoomListeners.removeAll()
            this.conversationNotifier?.dispose()
            this.roomNoticeDeclutterer.onRoomCleanup()
        })

        appDebuggingToggled.listen((enabled) => {
            if (enabled) {
                this.chatTab.chatTabContents.appendMessageDiv(
                    createLogMessage("Debug mode enabled. Type /debug again to disable."))
            } else {
                this.chatTab.chatTabContents.appendMessageDiv(createLogMessage("Debug mode disabled."))
            }
        })

        userCountUpdate.listen(() => {
            this.refreshTabs()
        })

        mentionUser.listen(username => {
            if (videoModeHandler.getVideoMode() === VideoMode.Split) {
                this.changeToTab(this.chatTab)
                this.chatTab.appendInputText(`@${username} `)
                this.chatTab.focusCurrentChatInput()
            }
        })

        let wasScrolledUp: boolean | undefined
        let resetScrollTimeout: number | undefined
        const scrollingResizeCallback = () => {
            // Record scroll state at start of resize
            if (wasScrolledUp === undefined) {
                wasScrolledUp = this.chatTab.chatTabContents.isScrolledUp()
            }

            // Reset scroll at the end of resize, ie only after the very last call to this callback
            window.clearTimeout(resetScrollTimeout)
            resetScrollTimeout = window.setTimeout(() => {
                if (wasScrolledUp === false) {
                    this.chatTab.chatTabContents.scrollToBottom()
                }
                wasScrolledUp = undefined
            }, 50)
        }

        ResizeHandle.splitModeDragResize.listen(() => {
            if (this.expandableDropDownMenu.dropDown.isShown()) {
                this.expandableDropDownMenu.dropDown.hideElement()
            }
            scrollingResizeCallback()
        })

        userInitiatedPm.listen(() => {
            window.setTimeout(() => {
                this.repositionChildrenRecursive()
            }, 0)
        })

        allPmsRead.listen((username) => {
            if (
                this.inPrivateRoom
                && (username === this.privateShowUser || username === this.room)
            ) {
                this.chatTab.resetNumUnread()
            }
        })

        privateMessage.listen((msg: IPrivateMessage) => {
            if (msg.isPrivateShowMessage === true) {
                this.newPrivateShowMessage(msg)
            }
        })

        repositionChatTabContent.listen(() => {
            this.repositionChildren()
        })

        goToSettingsTab.listen(() => {
            this.changeToTab(this.settingsTab)
        })
    }

    private initNoticeDeclutterer(): void {
        this.roomNoticeDeclutterer = new RoomNoticeDeclutterer({
            appendNotice: (notice: RoomNotice, countsForUnread: boolean) => this.chatTab.chatTabContents.appendMessageDiv(notice.element, countsForUnread),
            appendSentinelDiv: (div: HTMLDivElement) => this.chatTab.chatTabContents.element.appendChild(div),
        })
    }

    private shouldIncrementUnread(m: IRoomMessage): boolean {
        const isPrivateShowMessage = m.isPrivateShowMessage === true
        const isFromSelf = m.fromUser.username === pageContext.current.loggedInUser?.username

        return !isPrivateShowMessage
            || !isFromSelf && !this.isPmSessionActive(m.fromUser.username)
    }

    private newChatMessage(m: IRoomMessage): void {
        if (this.shouldIncrementUnread(m)) {
            this.chatTab.possiblyIncrementUnread()
        }
        MentionUserList.getInstance().addRecentUser(m.fromUser)
        const div = createRoomMessage(m)
        swapColors(div, document.body.classList.contains("darkmode"))
        this.chatTab.chatTabContents.appendMessageDiv(div, this.shouldIncrementUnread(m))
    }

    private newPrivateShowMessage(m: IPrivateMessage): void {
        this.newChatMessage(m)
        const photoMessage = createRoomPhotoMessage(m)

        if (photoMessage !== undefined) {
            this.chatTab.chatTabContents.appendMessageDiv(photoMessage, false)
        }

        this.maybeMarkPrivateShowPmsRead()
    }

    private newRoomNotice(roomNoticeData: IRoomNotice): void {
        const roomNotice = new SplitRoomNotice({
            roomNoticeData,
            isChatScrolledToBottom: () => !this.chatTab.chatTabContents.isScrolledUp(),
            scrollChatToBottom: () => this.chatTab.chatTabContents.debouncedScrollToBottom(),
            getChatScrollTop: () => this.chatTab.chatTabContents.getScrollTop(),
            setChatScrollTop: (top: number) => this.chatTab.chatTabContents.setScrollTop(top),
        })
        this.roomNoticeDeclutterer.addRoomNotice(roomNotice)
        if (roomNoticeData.showInPrivateMessage) {
            if (isPSRequestPMTabRedirectActive() && roomNoticeData.toUser !== undefined && roomNoticeData.toUser !== "") {
                this.pmTab.possiblyAppendMessageDivForUser(roomNoticeData.toUser, new SplitRoomNotice({ roomNoticeData, neverCollapse: true }).element)
            } else {
                this.pmTab.possiblyAppendMessageDiv(new SplitRoomNotice({ roomNoticeData, neverCollapse: true }).element)
            }
        }
    }

    private newPmChatLink(pmAnnouncement: IPMAnnouncement): void {
        const msg = i18n.newPrivateMessageNotice(pmAnnouncement.username)
        const msgDiv = createPMChatLinkMessage(msg, pmAnnouncement.PMChatLink.openConversationElement)
        this.chatTab.chatTabContents.appendMessageDiv(msgDiv)
    }

    private loadUnreadPmsForPrivateShow(): void {
        if (!this.inPrivateRoom) {
            return
        }

        const otherUser = isCurrentUserRoomOwner() ? this.privateShowUser : this.room
        getUnreadPms(otherUser, this.room, "0").then((unreadMessages) => {
            unreadMessages.forEach((message) => this.newPrivateShowMessage(message))
        }).catch(ignoreCatch)
    }

    private isPmSessionActive(username: string): boolean {
        return this.currentTab === this.pmTab && this.pmTab.getSession(username)?.isActive() === true
    }

    private maybeMarkPrivateShowPmsRead(): void {
        if (
            this.chatTab.active
            && this.inPrivateRoom
            && videoModeHandler.getVideoMode() === VideoMode.Split
        ) {
            allPmsRead.fire(isCurrentUserRoomOwner() ? this.privateShowUser : this.room)
        }
    }

    public changeToTab(t: BaseTab): void {
        super.changeToTab(t)
        this.maybeMarkPrivateShowPmsRead()
    }

    private constructExpandableDropDownMenu(): ExpandableDropDownMenu {
        const expandableDropDownMenu = new ExpandableDropDownMenu(undefined, true)
        addColorClass(expandableDropDownMenu, "tab")
        addColorClass(expandableDropDownMenu.dropDown, "chat-tabs-dropdown-border")
        applyStyles(expandableDropDownMenu.dropDown, {
            width: "105px",
            padding: "8px 0",
            borderWidth: "1px",
            borderStyle: "solid",
        })

        applyStyles(expandableDropDownMenu, {
            ...this.tabHandleStyle,
            position: "absolute",
            height: "19px",
            width: "32px",
        })

        return expandableDropDownMenu
    }

    protected createChatTab(): ChatTab {
        return new ChatTab()
    }

    protected attachTabToDOM(c: BaseTab): Component {
        const tabHandle = super.attachTabToDOM(c)
        tabHandle.element.classList.add("chat-tab-handle")
        return tabHandle
    }

    private async handleBanSilence(m: IBanSilenceInfo, context: IRoomContext): Promise<void> {
        if (isCurrentUserRoomOwner() || m.silencer === context.dossier.userName) {
            const banId = await getBanID({
                bannedUser: m.silenced,
                createdBy: m.silencer,
                isSilence: !m.isBan,
                roomUser: context.dossier.room,
            })
            const logFunction = (msg: string) => { this.showLogMsg(msg) }
            const divOptions = createUndoOptions(this.chatTab.chatTabContents, m, banId, context, logFunction)
            this.chatTab.chatTabContents.appendMessageDiv(divOptions)
        }
    }

    private showLogMsg(msg: string): void {
        this.chatTab.chatTabContents.appendMessageDiv(createLogMessage(msg))
    }

    public repositionChildren(): void {
        this.tabHandlesRow.repositionChildrenRecursive()
    }

    public messageListWrapperHeight(): number {
        return this.element.clientHeight - inputDivHeight - this.tabHandlesRow.element.offsetHeight - 6
    }

    public getCurrentTab(): IChatWindowTab {
        return super.getCurrentTab() as IChatWindowTab
    }

    public children(): IChatWindowTab[] {
        return super.children() as IChatWindowTab[]
    }

    public cycleToNextWindow(): void {
        this.cycleToWindow("next")
    }

    public cycleToPrevWindow(): void {
        this.cycleToWindow("prev")
    }

    private cycleToWindow(direction: "next" | "prev"): void {
        const cycle = direction === "next" ? () => this.pmTab.showNextPMSession() : () => this.pmTab.showPrevPMSession()
        const resetCycle = direction === "next" ? () => this.pmTab.resetNextQueue() : () => this.pmTab.resetPrevQueue()
        this.settingsTab.getChatSettings().hideModals()
        if (super.getCurrentTab() === this.chatTab) {
            this.changeToTab(this.pmTab)
            resetCycle()
            if (!cycle()) {
                this.pmTab.showPMList(false)
            }
        } else {
            if (!cycle()) {
                this.changeToTab(this.chatTab)
            }
        }
        this.repositionChildrenRecursive()
    }

    public closeCurrentPMSession(): void {
        if (super.getCurrentTab() === this.pmTab) {
            this.pmTab.closeCurrentPMSession()
        }
    }
}
