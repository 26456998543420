import { ArgJSONMap } from "@multimediallc/web-utils"

export const enum BannedTermTypes {
    Exact = "exact",
    Substring = "substring",
}

export interface IBannedTerm {
    term: string,
    banType: BannedTermTypes,
}

export interface IBannedTermsData {
    bannedTerms: IBannedTerm[],
    pageCount: number,
    currentPage: number,
}

export function parseBannedTerms(rawData: string): IBannedTermsData {
    const dataMap = new ArgJSONMap(rawData)
    const termsList = dataMap.getList("banned_terms")
    let bannedTerms: IBannedTerm[] = []
    if (termsList !== undefined) {
        bannedTerms = termsList.map(termData => {
            return {
                term: termData.getString("term"),
                banType: termData.getString("ban_type") as BannedTermTypes,
            }
        })
    }
    return {
        bannedTerms: bannedTerms,
        pageCount: dataMap.getNumber("page_count", false),
        currentPage: dataMap.getNumber("current_page", false),
    }
}
