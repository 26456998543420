import { ArgJSONMap } from "@multimediallc/web-utils"
import { banUser } from "../../../cb/api/chatBansAndSilences"
import { addColorClass, colorClass } from "../../../cb/colorClasses"
import { addEventListenerPoly } from "../../addEventListenerPolyfill"
import { modalAlert } from "../../alerts"
import { getCb, normalizeResource, postCb } from "../../api"
import { roomLoaded } from "../../context"
import { isCharacterOrBackspaceKey } from "../../eventsUtil"
import { i18n } from "../../translation"
import { naturalTime } from "../timeUtils"
import { BaseSettingsModal } from "./baseSettingsModal"
import { SettingsTable } from "./settingsTable"

export interface IBanSilence {
    username: string,
    created_by: string,
    expires_at: string,
    created_at: string,
    id: number,
}

export interface IBansAndSilencesData {
    perm: string,
    banDict: {
        bans: IBanSilence[],
        pageCount: number,
        currentPage: number,
        hiddenBans: number,
    },
    silenceDict: {
        silences: IBanSilence[],
        pageCount: number,
        currentPage: number,
        hiddenSilences: number,
    }
}

function parseBansAndSilences(rawData: string): IBansAndSilencesData {
    const dataMap = new ArgJSONMap(rawData)
    const banDict = dataMap.getMap("ban_dict", false)
    const silenceDict = dataMap.getMap("silence_dict", false)
    return {
        perm: dataMap.getString("perm"),
        banDict: {
            bans: parseBanAndSilenceList(banDict.getList("bans")),
            pageCount: banDict.getNumber("page_count", false),
            currentPage: banDict.getNumber("current_page", false),
            hiddenBans: banDict.getNumber("hidden_bans", false),
        },
        silenceDict: {
            silences: parseBanAndSilenceList(silenceDict.getList("silences")),
            pageCount: silenceDict.getNumber("page_count", false),
            currentPage: silenceDict.getNumber("current_page", false),
            hiddenSilences: silenceDict.getNumber("hidden_silences", false),
        },
    }
}

function parseBanAndSilenceList(list: ArgJSONMap[] = []): IBanSilence[] {
    const data = []
    for (const banAndSilence of list) {
        data.push({
            username: banAndSilence.getString("username"),
            created_by: banAndSilence.getString("created_by"),
            expires_at: banAndSilence.getString("expires_at"),
            created_at: banAndSilence.getString("created_at"),
            id: banAndSilence.getNumber("id"),
        })
    }
    return data
}

let roomName: string

// eslint-disable-next-line @multimediallc/no-global-listener
roomLoaded.listen((context) => {
    roomName = context.chatConnection.room()
})

const enum BanType {
    Banned = "banned",
    Silenced = "silenced",
}

export class BansAndSilencesModal extends BaseSettingsModal<IBansAndSilencesData> {
    private silenceTable: SettingsTable<IBanSilence>
    private banTable: SettingsTable<IBanSilence>
    private paginationLimit = 12
    private clearHiddenSilences = document.createElement("p")
    private clearHiddenBans = document.createElement("p")
    private clearHiddenSilencesMessage = document.createTextNode("")
    private clearHiddenBansMessage = document.createTextNode("")
    private banType: BanType[] = [BanType.Banned, BanType.Silenced]
    private page: number | undefined

    constructor() {
        super()

        addColorClass(this.element, colorClass.textColor)
        this.contents.style.width = "720px"
        this.contents.style.padding = "10px"

        this.silenceTable = new SettingsTable({
            title: i18n.silencedTableTitle,
            info: i18n.silencedUsersInfo,
            onPageRequest: (page) => {
                this.banType = [BanType.Silenced]
                this.page = page
                this.reload()
            },
            rowHeaders: [i18n.usernameText, i18n.silenced, i18n.expires, i18n.actions],
            rowGenerators: [
                (props) => this.createRowName(props),
                (props) => this.createRowCreationInfo(props),
                (props) => this.createRowExpires(props),
                (props) => this.createRowActions(props, false),
            ],
            minHeight: "286px",
        })

        this.banTable = new SettingsTable({
            title: i18n.bannedTableTitle,
            info: i18n.bannedUsersInfo,
            onPageRequest: (page) => {
                this.banType = [BanType.Banned]
                this.page = page
                this.reload()
            },
            rowHeaders: [i18n.usernameText, i18n.banned, i18n.expires, i18n.actions],
            rowGenerators: [
                (props) => this.createRowName(props),
                (props) => this.createRowCreationInfo(props),
                (props) => this.createRowExpires(props),
                (props) => this.createRowActions(props, true),
            ],
            minHeight: "286px",
        })

        const divider = document.createElement("hr")
        divider.style.marginTop = "12px"

        this.createClearHiddenSilencesAndBans(this.clearHiddenSilences, this.clearHiddenSilencesMessage)
        this.createClearHiddenSilencesAndBans(this.clearHiddenBans, this.clearHiddenBansMessage)

        this.contents.appendChild(this.silenceTable.element)
        this.contents.appendChild(this.clearHiddenSilences)
        this.contents.appendChild(divider)
        this.contents.appendChild(this.banTable.element)
        this.contents.appendChild(this.clearHiddenBans)
        this.contents.appendChild(document.createElement("br"))
        this.contents.appendChild(this.createAddBansForm())
    }

    protected loadData(): Promise<IBansAndSilencesData | void> {
        let url = "api/ts/chat/ban-list/"
        if (this.page !== undefined) {
            url = `api/ts/chat/ban-list/?ban_types=${this.banType.toString()}&page=${this.page}&limit=${this.paginationLimit}`
        }
        return getCb(url).then((xhr) => {
            return parseBansAndSilences(xhr.responseText)
        })
    }

    protected onDataLoaded(): void {
        this.silenceTable.updatePagination(this.data.silenceDict.pageCount, this.data.silenceDict.currentPage)
        this.banTable.updatePagination(this.data.banDict.pageCount, this.data.banDict.currentPage)
        this.generateTables()
        this.generateClearSilencesAndBansMessages()
        this.resetLoadParams()
    }

    private generateTables(): void {
        this.resetTables()
        if (this.banType.includes(BanType.Silenced)) {
            for (const row of this.data.silenceDict.silences) {
                if (!this.isUserBanned(row.username)) {
                    this.silenceTable.addRow(row)
                }
            }
        }
        if (this.banType.includes(BanType.Banned)) {
            for (const row of this.data.banDict.bans) {
                this.banTable.addRow(row)
            }
        }
    }

    private isUserBanned(username: string): boolean {
        for (const user of this.data.banDict.bans) {
            if (username === user.username) {
                return true
            }
        }
        return false
    }

    private resetTables(): void {
        if (this.banType.includes(BanType.Silenced)) {
            this.silenceTable.reset()
        }
        if (this.banType.includes(BanType.Banned)) {
            this.banTable.reset()
        }
    }

    private createRowName(data: IBanSilence): HTMLTableCellElement {
        const username = data.username

        const td = document.createElement("td")
        const name = document.createElement("a")
        addColorClass(name, colorClass.hrefColor)
        name.href = normalizeResource(`/${username}`)
        name.target = "_blank"
        name.innerText = username
        name.style.fontSize = "15px"
        td.style.textAlign = "center"
        name.style.textOverflow = "ellipsis"
        name.style.overflow = "hidden"
        name.style.marginTop = "4px"

        td.appendChild(name)
        return td
    }

    private createRowCreationInfo(data: IBanSilence): HTMLTableCellElement {
        const createdAt = data.created_at
        const createdBy = data.created_by

        const td = document.createElement("td")
        td.title = i18n.createdAt(new Date(createdAt))
        td.style.fontSize = "12px"
        td.style.textAlign = "center"
        td.style.textOverflow = "ellipsis"
        td.style.overflow = "hidden"
        td.style.marginTop = "4px"

        const createdAtSpan = document.createElement("span")
        createdAtSpan.innerText = naturalTime(new Date(), new Date(createdAt))

        const createdBySpan = this.createByUsername(`\u00a0${i18n.createdByUsername(createdBy)}`)

        td.appendChild(createdAtSpan)
        td.appendChild(createdBySpan)
        return td
    }

    private createRowExpires(data: IBanSilence): HTMLTableCellElement {
        const expiresAt = data.expires_at
        const isPermanent = new Date(data.expires_at) > new Date(this.data.perm)

        const td = document.createElement("td")
        td.title = i18n.expiresOn(new Date(expiresAt))
        td.innerText = isPermanent ? i18n.never : naturalTime(new Date(), new Date(expiresAt))
        td.style.textAlign = "center"
        td.style.fontSize = "12px"
        td.style.textOverflow = "ellipsis"
        td.style.overflow = "hidden"

        return td
    }

    private createRowActions(data: IBanSilence, isBan: boolean): HTMLTableCellElement {
        const silenceID = data.id
        const isPermanent = new Date(data.expires_at) > new Date(this.data.perm)

        const td = document.createElement("td")
        const remove = document.createElement("a")
        const convert = document.createElement("a")
        const pipe = document.createTextNode(" | ")

        addColorClass(remove, colorClass.hrefColor)
        addColorClass(convert, colorClass.hrefColor)
        remove.style.cursor = "pointer"
        convert.style.cursor = "pointer"

        remove.innerText = i18n.remove
        convert.innerText = isBan ? i18n.makePermanent : i18n.convertToBan

        td.style.textAlign = "center"

        td.appendChild(remove)
        if (!isPermanent) {
            td.appendChild(pipe)
            td.appendChild(convert)
        }

        remove.onclick = () => {
            this.editRoomBan(silenceID, isBan ? "remove_ban" : "remove_silence")
        }
        convert.onclick = () => {
            this.editRoomBan(silenceID, isBan ? "ban_perm" : "convert_to_ban")
        }

        return td
    }

    private createByUsername(text: string): HTMLSpanElement {
        const span = document.createElement("span")
        addColorClass(span, "byUsername")
        span.innerText = text
        span.style.fontSize = "0.8em"

        return span
    }

    private editRoomBan(ID: number, action: string): void {
        postCb(
            "edit_room_ban/",
            { "banid": String(ID), "action": action, "room_username": roomName },
        ).then((xhr) => {
            const responseText: null | string = xhr.responseText
            if (responseText === null) {
                modalAlert(i18n.errorOccurred)
                this.reload()
                return
            }
            let response: {
                error: string | undefined,
                reload: boolean | undefined,
            }
            try {
                response = JSON.parse(responseText)
            } catch {
                modalAlert(i18n.errorOccurred)
                this.reload()
                return
            }
            if (response["error"] !== undefined) {
                modalAlert(response["error"])
                if (response["reload"] !== undefined && response["reload"] === true) {
                    this.reload()
                }
            } else {
                this.reload()
            }
        }).catch(() => {
            modalAlert(i18n.errorOccurred)
        })
    }

    private getNumberOfHiddenSilences(): number {
        if (!this.data.silenceDict.hiddenSilences) {
            return 0
        }
        return this.data.silenceDict.hiddenSilences
    }

    private getNumberOfHiddenBans(): number {
        if (!this.data.banDict.hiddenBans) {
            return 0
        }
        return this.data.banDict.hiddenBans
    }

    private createClearHiddenSilencesAndBans(container: HTMLElement, message: Node): void {
        container.style.textAlign = "center"
        container.appendChild(message)

        const clearHiddenLink = document.createElement("a")
        addColorClass(clearHiddenLink, colorClass.hrefColor)
        clearHiddenLink.style.cursor = "pointer"
        clearHiddenLink.innerText = i18n.clickToClearHiddenBansAndSilences
        clearHiddenLink.onclick = () => {
            postCb(
                `clear_hidden_banned_users/${roomName}/`,
                {},
            ).then((request) => {
                const isAsync = new ArgJSONMap(request.responseText).getBooleanOrUndefined("is_async")
                if (isAsync === true) {
                    modalAlert(i18n.hiddenBansRequestProcessing)
                }
                this.reload()
            }).catch(() => {
                modalAlert(i18n.hiddenBansRequestError)
            })
        }

        container.appendChild(document.createElement("br"))
        container.appendChild(clearHiddenLink)
    }

    private generateClearSilencesAndBansMessages(): void {
        const silences = this.getNumberOfHiddenSilences()
        const bans = this.getNumberOfHiddenBans()

        this.clearHiddenSilences.style.display = silences === 0 ? "none" : ""
        this.clearHiddenBans.style.display = bans === 0 ? "none" : ""

        this.clearHiddenSilencesMessage.textContent = i18n.automaticallySilencedUsersMessage(silences)
        this.clearHiddenBansMessage.textContent = i18n.automaticallyBannedUsersMessage(bans)
    }

    private createAddBansForm(): HTMLElement {
        const form = document.createElement("form")
        const label = document.createElement("label")
        const input = document.createElement("input")
        const submit = document.createElement("button")

        addColorClass(submit, "submitButton")
        submit.style.borderWidth = "1px"
        submit.style.borderStyle = "solid"
        submit.style.borderRadius = "4px"
        submit.style.cursor = "pointer"
        submit.style.fontFamily = "'UbuntuMedium', Arial, Helvetica, sans-serif"

        label.innerText = `${i18n.addBan}:`
        input.type = "text"
        input.placeholder = i18n.enterUsernameToBan
        submit.type = "submit"
        submit.innerText = i18n.inlineBanText

        form.appendChild(label)
        form.appendChild(input)
        form.appendChild(submit)

        addColorClass(form, "bluetxt")
        form.style.fontSize = "1.12em"
        form.style.fontFamily = "'UbuntuMedium', Arial, Helvetica, sans-serif"
        form.style.fontWeight = "normal"
        form.style.marginBottom = "20px"
        form.style.textAlign = "center"

        input.style.width = "160px"
        input.style.height = "16px"
        input.style.margin = "0 3px"

        addEventListenerPoly("keydown", document, (e: KeyboardEvent) => {
            if (document.body.contains(this.element) && document.activeElement !== input && isCharacterOrBackspaceKey(e)) {
                input.focus()
            }
        })

        form.onsubmit = (event: Event) => {
            event.preventDefault()
            const username = input.value.toLowerCase()
            if (username === "") {
                modalAlert(i18n.usernameToBan, () => input.focus())
                return
            }
            banUser(username, roomName).then(() => {
                input.value = ""
                modalAlert(i18n.banUserSuccess(username), () => input.focus())
                this.reload()
            }).catch((e: Error) => {
                modalAlert(e.message, () => input.focus())
            })
        }

        return form
    }

    private resetLoadParams(): void {
        this.banType = [BanType.Banned, BanType.Silenced]
        this.page = undefined
    }
}
