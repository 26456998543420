import { ArgJSONMap } from "@multimediallc/web-utils"
import { addColorClass, removeColorClass } from "../../../cb/colorClasses"
import { deleteCb, getCb, postCb } from "../../api"
import { i18n } from "../../translation"
import { BaseSettingsModal } from "./baseSettingsModal"
import { SettingsTable } from "./settingsTable"
import type { XhrError } from "../../api"

export interface IRegionBlockData {
    geos: string[],
    pageCount: number,
    currentPage: number,
}

function parseRegionBlocks(rawData: string): IRegionBlockData {
    const dataMap = new ArgJSONMap(rawData)
    return {
        geos: dataMap.getStringList("geos"),
        pageCount: dataMap.getNumber("page_count", false),
        currentPage: dataMap.getNumber("current_page", false),
    }
}

export class RegionBlockModal extends BaseSettingsModal<IRegionBlockData> {
    private table: SettingsTable<string>
    private page = 1
    private paginationLimit = 12
    private searchTerm = ""
    private apiUrl = "api/ts/chat/geo-list/"
    private addUsernameForm: HTMLFormElement
    private usernameInput: HTMLInputElement
    private inputResponseMsg = document.createElement("div")

    constructor() {
        super()

        this.table = new SettingsTable({
            title: i18n.regionBlockExemptions,
            info: i18n.regionBlockExemptionsInfo,
            onPageRequest: (page) => {
                this.page = page
                this.reload()
            },
            rowHeaders: [this.createUsernameHeader(), i18n.remove],
            rowGenerators: [
                (username: string) => this.createUsernameCell(username),
                (username: string) => this.createRemoveCell(username),
            ],
            minHeight: "350px",
        })
        this.table.element.style.marginBottom = "10px"
        this.contents.appendChild(this.table.element)

        this.buildAddUsernameForm()
        this.contents.appendChild(this.addUsernameForm)
    }

    private createUsernameHeader(): HTMLSpanElement {
        const headerCell = document.createElement("span")
        headerCell.style.display = "inline-block"
        headerCell.style.width = "100%"
        headerCell.style.textAlign = "left"
        headerCell.style.paddingLeft = "29px"
        headerCell.textContent = i18n.usernameText
        return headerCell
    }

    private createUsernameCell(username: string): HTMLTableCellElement {
        const usernameCell = document.createElement("td")
        usernameCell.style.height = "20px"
        usernameCell.style.width = "200px"
        usernameCell.style.fontSize = "14px"
        usernameCell.style.textAlign = "left"
        usernameCell.style.textOverflow = "ellipsis"
        usernameCell.style.overflow = "hidden"
        usernameCell.style.display = "block"
        usernameCell.style.paddingLeft = "32px"
        usernameCell.style.marginTop = "4px"
        usernameCell.textContent = username
        return usernameCell
    }

    private createRemoveCell(username: string): HTMLTableCellElement {
        const cell = document.createElement("td")
        cell.style.width = "140px"
        const closeButton = document.createElement("div")
        addColorClass(closeButton, "removeButton")
        closeButton.style.margin = "auto"
        closeButton.style.width = "14px"
        closeButton.style.height = "14px"
        closeButton.style.cursor = "pointer"
        closeButton.style.opacity = ".5"
        closeButton.onclick = () => this.delete(username)
        closeButton.onmouseenter = () => {closeButton.style.opacity = "1"}
        closeButton.onmouseleave = () => {closeButton.style.opacity = ".5"}
        cell.appendChild(closeButton)
        return cell
    }

    private buildAddUsernameForm(): void {
        const addUsernameForm = document.createElement("form")
        addUsernameForm.style.fontSize = "1.12em"
        addUsernameForm.style.fontFamily = "'UbuntuMedium', Arial, Helvetica, sans-serif"
        addUsernameForm.style.fontWeight = "normal"
        addUsernameForm.style.textAlign = "center"
        addUsernameForm.style.paddingLeft = "4px"

        const usernameInputID = "regionBlockInput"

        const label = document.createElement("label")
        addColorClass(label, "bluetxt")
        label.innerText = `${i18n.addUsername}:`
        label.htmlFor = usernameInputID
        addUsernameForm.appendChild(label)

        const usernameInput = document.createElement("input")
        addColorClass(usernameInput, "select")
        usernameInput.id = usernameInputID
        usernameInput.style.width = "134px"
        usernameInput.style.height = "16px"
        usernameInput.style.margin= "0 8px"
        usernameInput.type = "text"
        usernameInput.placeholder = i18n.enterUsernameToExemptRegionBlock
        addUsernameForm.appendChild(usernameInput)

        const submitButton = document.createElement("button")
        addColorClass(submitButton, "submitButton")
        submitButton.style.cursor = "pointer"
        submitButton.style.fontFamily = "'UbuntuMedium', Arial, Helvetica, sans-serif"
        submitButton.type = "submit"
        submitButton.innerText = i18n.add
        addUsernameForm.appendChild(submitButton)

        const inputResponseMsg = document.createElement("div")
        inputResponseMsg.style.marginTop = "4px"
        addUsernameForm.appendChild(inputResponseMsg)

        addUsernameForm.onsubmit = (event: Event) => {
            event.preventDefault()
            this.submit()
        }

        this.usernameInput = usernameInput
        this.inputResponseMsg = inputResponseMsg
        this.addUsernameForm = addUsernameForm
    }

    private responseTimer: number
    private setInputResponseMessage(message: string, isError: boolean): void {
        if (isError) {
            removeColorClass(this.inputResponseMsg, "bluetxt")
            addColorClass(this.inputResponseMsg, "inputError")
            this.inputResponseMsg.style.fontSize = "0.8em"
        } else {
            removeColorClass(this.inputResponseMsg, "inputError")
            addColorClass(this.inputResponseMsg, "bluetxt")
            this.inputResponseMsg.style.fontSize = "0.8em"
        }
        this.inputResponseMsg.innerText = message
        this.inputResponseMsg.style.transition = "opacity 0ms"
        this.inputResponseMsg.style.opacity = "1"
        clearTimeout(this.responseTimer)
        this.responseTimer = window.setTimeout(() => {
            this.inputResponseMsg.style.transition = "opacity 1000ms"
            this.inputResponseMsg.style.opacity = "0"
        }, 2500)
    }

    protected loadData(): Promise<IRegionBlockData | void> {
        let url
        if (this.searchTerm !== "") {
            url = `${this.apiUrl}?limit=${this.paginationLimit}&search_term=${this.searchTerm}`
            this.searchTerm = ""
        } else {
            url = `${this.apiUrl}?page=${this.page}&limit=${this.paginationLimit}`
        }
        return getCb(url).then((xhr) => {
            return parseRegionBlocks(xhr.responseText)
        })
    }

    protected onDataLoaded(): void {
        this.page = this.data.currentPage
        this.table.updatePagination(this.data.pageCount, this.page)

        this.table.reset()
        this.usernameInput.focus()
        for (const username of this.data.geos.sort()) {
            this.table.addRow(username)
        }
    }

    private submit(): void {
        const username = this.usernameInput.value
        this.usernameInput.value = ""
        postCb(this.apiUrl, { "username": username }).then(() => {
            this.searchTerm = username
            this.reload()
            this.setInputResponseMessage(i18n.userAddedToExemptList, false)
        }).catch(this.handleAPIError).finally(() => this.usernameInput.focus())
    }

    private delete(username: string): void {
        deleteCb(this.apiUrl, { "username": username }).then(() => {
            this.reload()
        }).catch(this.handleAPIError)
    }

    private handleAPIError = (err: XhrError): void => {
        const response = JSON.parse(err.xhr.responseText)
        if (typeof response["error"] === "string") {
            this.setInputResponseMessage(response["error"], true)
        } else {
            this.setInputResponseMessage(i18n.anErrorOccurred, true)
        }
    }
}
