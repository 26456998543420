import { addColorClass, colorClass } from "../../cb/colorClasses"
import { ReactComponentRegistry } from "../../cb/components/ReactRegistry"
import { pageContext } from "../../cb/interfaces/context"
import { ChatButtonHolder, ChatButtonType } from "../../cb/ui/chatButtonHolder"
import { CollapsibleTab, TabId } from "../../cb/ui/tabs"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { BroadcastChatSettings } from "../broadcastlib/broadcastChatSettings/broadcastChatSettings"
import { ChatSettings } from "../chatSettings"
import { applyStyles } from "../DOMutils"
import { addPageAction } from "../newrelic"
import { styleTransition } from "../safeStyle"
import { cogSvg } from "../svgElement"
import { i18n } from "../translation"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import {
    createInputDiv,
    createInputField,
    createInputForm,
    maxInputChat, maxInputPm, SendButtonVariant,
} from "./chatTabContents"
import type { IChatWindowTab } from "../../cb/ui/iChatTab"
import type { CustomInput } from "../customInput"

export class ChatSettingsTab extends CollapsibleTab implements IChatWindowTab {
    private inputField: CustomInput
    private inputDiv: HTMLDivElement
    private buttonHolder: ChatButtonHolder
    protected chatSettings = this.createChatSettings()
    private settingInput = false

    constructor() {
        super()

        this.element.id = "SplitModeChatSettingsTab"
        this.element.dataset.testid = "chat-settings-tab"
        addColorClass(this.element, colorClass.defaultColor)
        this.element.style.fontSize = "12px"
        this.element.style.fontFamily = "Tahoma, Arial, Helvetica, sans-serif"
        this.element.style.boxSizing = "border-box"

        const container = document.createElement("div")
        applyStyles(container, {
            display: "flex",
            flexDirection: "column",
            height: "100%",
        })
        this.element.appendChild(container)

        applyStyles(this.chatSettings, {
            flex: 1,
            height: "",
        })

        this.inputDiv = createInputDiv()
        const form = createInputForm()
        this.inputField = createInputField(() => false, Math.max(maxInputChat, maxInputPm))
        const switchToChatTab = () => {
            if (!this.settingInput) {
                this.chatSettings.hideModals()
                if (this.parent !== undefined) {
                    this.parent.changeToFirstTab()
                }
            }
        }
        addEventListenerPoly("click", this.inputField.element, switchToChatTab)
        addEventListenerPoly("focus", this.inputField.element, switchToChatTab)

        const sendButtonRoot = document.createElement("span")
        const SendButton = ReactComponentRegistry.get("SendButton")
        new SendButton({
            "onClick": switchToChatTab,
            "isPm": false,
            "variant": SendButtonVariant.SplitMode,
        }, sendButtonRoot)
        this.buttonHolder = new ChatButtonHolder()
        this.buttonHolder.addButton(sendButtonRoot, ChatButtonType.Text)
        form.appendChild(this.inputField.element)
        this.inputDiv.appendChild(form)
        this.inputDiv.appendChild(this.buttonHolder.element)
        container.appendChild(this.inputDiv)

        const saveNotice = document.createElement("div")
        saveNotice.dataset.testid = "save-notice"
        saveNotice.innerText = i18n.chatSettingsSavedText
        saveNotice.style.position = "absolute"
        saveNotice.style.top = "30px"
        saveNotice.style.right = "10px"
        saveNotice.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"
        saveNotice.style.color = "#000000"
        saveNotice.style.backgroundColor = "#d8deea"
        saveNotice.style.border = "1px solid #acacac"
        saveNotice.style.borderRadius = "4px"
        saveNotice.style.padding = "8px 12px"
        saveNotice.style.visibility = "hidden"
        this.element.appendChild(saveNotice)

        videoModeHandler.changeVideoMode.listen(() => {
            if (videoModeHandler.getVideoMode() === VideoMode.Split) {
                container.insertBefore(this.chatSettings.element,this.inputDiv)
                this.chatSettings.element.style.margin = "0"
            }
        })

        let saveNoticeTimeout: number
        this.chatSettings.chatSettingsSavedRequest.listen(() => {
            clearTimeout(saveNoticeTimeout)
            styleTransition(saveNotice, "250ms")
            saveNotice.style.visibility = "visible"
            saveNoticeTimeout = window.setTimeout(() => {
                styleTransition(saveNotice, "1000ms")
                saveNotice.style.visibility = "hidden"
            }, 3000)
        })
    }

    protected getHandleStyle(): CSSX.Properties {
        const props: CSSX.Properties = {
            ...super.getHandleStyle(),
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            boxSizing: "border-box",
            minHeight: "20px",
            height: "unset",
        }
        props.minWidth = "34px"
        return props
    }

    protected createChatSettings(): ChatSettings {
        if (pageContext.current.isBroadcast) {
            return new BroadcastChatSettings()
        } else{
            return new ChatSettings()
        }
    }

    public getChatSettings(): ChatSettings {
        return this.chatSettings
    }

    protected getTabHandleContent(): Node[] {
        if (this.collapsed) {
            return [document.createTextNode(i18n.chatSettingsTabHandleText)]
        } else {
            return [cogSvg(0)]
        }
    }

    public getTabId(): TabId {
        return TabId.SettingsDefault
    }

    public focusCurrentChatInput(): void {
        this.inputField.focus()
    }

    public blurCurrentChatInput(): void {
        this.inputField.blur()
    }

    public isInputFocused(): boolean {
        return document.activeElement === this.inputField.element
    }

    public scrollToBottom(): void {
        this.chatSettings.element.scrollTop = this.chatSettings.element.scrollHeight - this.chatSettings.element.offsetHeight
    }

    public getChatInputField(): CustomInput {
        return this.inputField
    }

    protected tabHandleClicked(_: MouseEvent): void {
        super.tabHandleClicked(_)
        addPageAction("FocusTab", { "location": "chatSettings" })
    }
}
