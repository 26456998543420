import { addColorClass } from "../../../cb/colorClasses"
import { modalAlert } from "../../alerts"
import { ModalComponent } from "../../modalComponent"

export interface ISettingsModal {
    reload: () => void,
    show: () => void
}

const closeButtonHeight = 14
export abstract class BaseSettingsModal<T> extends ModalComponent implements ISettingsModal {
    private isLoading = false
    private loadingDiv = document.createElement("div")
    protected contents = document.createElement("div")
    protected preventChatFocus = true
    protected data: T

    constructor() {
        super({
            onShow: () => {
                this.element.style.display = "block"
                this.repositionChildrenRecursive()
            },
            onHide: () => {
                this.element.style.display = "none"
            },
            easyExit: true,
        })
        addColorClass(this.element, "BaseSettingsModal")
        this.element.style.display = "none"
        this.element.style.height = "auto"
        this.element.style.width = "auto"
        this.element.style.position = "absolute"
        this.element.style.borderRadius = "6px"
        this.element.style.padding = "10px"
        this.element.style.fontSize = "12px"
        this.overlay.style.backgroundColor = "#000000"
        this.overlay.style.opacity = "0.4"
        this.element.appendChild(this.contents)
        this.element.appendChild(this.loadingDiv)
        const loadingIndicator = document.createElement("img")
        loadingIndicator.src = `${STATIC_URL}loading.gif`
        loadingIndicator.style.width = "50px"
        loadingIndicator.style.height = "50px"
        loadingIndicator.style.padding = `${closeButtonHeight}px`
        loadingIndicator.style.position = "absolute"
        loadingIndicator.style.left = "50%"
        loadingIndicator.style.top = "50%"
        loadingIndicator.style.transform = "translate(-50%, -50%"
        this.loadingDiv.appendChild(loadingIndicator)

        const closeButton = this.createCloseButton()
        this.element.appendChild(closeButton)
    }

    private createCloseButton(): HTMLDivElement {
        const closeButton = document.createElement("div")
        addColorClass(closeButton, "closeButton")
        closeButton.style.position = "absolute"
        closeButton.style.width = `${closeButtonHeight}px`
        closeButton.style.height = `${closeButtonHeight}px`
        closeButton.style.top = "10px"
        closeButton.style.right = "10px"
        closeButton.style.cursor = "pointer"
        closeButton.style.opacity = ".5"
        closeButton.onclick = () => {
            this.hide()
        }
        closeButton.onmouseenter = () => {
            closeButton.style.opacity = "1"
        }
        closeButton.onmouseleave = () => {
            closeButton.style.opacity = "0.5"
        }
        return closeButton
    }

    private setLoading(isLoading: boolean): void {
        this.isLoading = isLoading
        if (this.isLoading) {
            this.contents.style.visibility= "hidden"
            this.loadingDiv.style.display = "block"
        } else {
            this.contents.style.visibility = "visible"
            this.loadingDiv.style.display= "none"
        }
        this.repositionChildrenRecursive()
    }

    public reload(): void {
        if (this.isLoading) {
            return
        }
        this.setLoading(true)
        this.loadData().then((data: T) => {
            if (data === undefined) {
                this.hide()
                return
            }
            this.data = data
            this.setLoading(false)
            this.onDataLoaded()
            this.repositionChildrenRecursive()
        }).catch((err) => {
            this.setLoading(false)
            this.hide()
            modalAlert("An error has occurred")
            error(`Error loading settings modal: ${err}`)
        })
    }

    protected abstract loadData(): Promise<T | void>

    protected onDataLoaded(): void {}

    protected repositionChildren(): void {
        const left = (document.documentElement.clientWidth - this.element.offsetWidth) / 2
        const top = (document.documentElement.clientHeight - this.element.offsetHeight) / 2
        this.element.style.left = `${Math.max(left + window.pageXOffset, 0)}px`
        this.element.style.top = `${Math.max(top + window.pageYOffset, 0)}px`
    }
}
